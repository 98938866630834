import Image from 'next/image'
import { css, Theme } from '@emotion/react'
import { Box, Typography } from '@mui/material'

import { CVButton } from '~/components/atoms/CVButton'

import Points from 'public/point.svg'
import authority from 'public/authority.webp'

const HeroAdvertising: React.FC = () => {
  return (
    <div css={styles.container}>
      <div css={styles.image}>
        <div css={styles.inner}>
          <Image
            id='hero'
            src='/ad_hero.svg'
            layout='fill'
            objectFit='contain'
          />
        </div>
      </div>
      <Box css={styles.content}>
        <Typography
          id='copy'
          variant='h1'
          component='h1'
          css={styles.copy}
          color='primary'>
          生徒数を増やす<span>ウェブ広告</span>
          {<br />}
          はじめませんか？
        </Typography>
        <p css={styles.points} id='points'>
          <Points />
        </p>
        <div css={styles.btn_container} id='cvbutton'>
          <CVButton
            url='/form'
            label='お見積・無料相談はこちら'
            micro='＼塾・教室業のウェブ集客にはコツがあります／'
          />
        </div>
      </Box>
      <div css={styles.authority}>
        <Image
          src={authority}
          width={1362}
          height={248}
          layout='intrinsic'
          alt='ウェブ解析士・IMA検定など有資格者がサポートいたします！'
        />
      </div>
    </div>
  )
}

const styles = {
  container: (theme: Theme) => css`
    position: relative;
    background-color: ${theme.palette.background.default};
    overflow: hidden;
    height: 100%;
    width: 100%;
  `,
  image: css`
    position: absolute;
    top: 35%;
    right: 0;
    height: 100%;
    width: 100%;
    @media (min-width: 960px) {
      top: 15%;
      width: 900px;
    }
  `,
  inner: css`
    position: relative;
    width: 100%;
    height: 100%;
  `,
  content: css`
    position: absolute;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    width: 90vw;
    max-width: 1080px;
    top: 17%;
    left: 5%;
    @media (min-width: 960px) {
      justify-content: start;
      align-items: flex-start;
      width: 80vw;
    }
    @media (min-width: 1200px) {
      width: 70%;
      top: 25%;
      left: calc(15% - (500px - 25vw));
    }
  `,
  copy: css`
    font-style: normal;
    font-weight: 900;
    text-align: center;
    line-height: 3rem;
    font-size: 6vw;
    & span {
      color: #2ea595;
    }
    @media (min-width: 960px) {
      text-align: start;
      line-height: 6rem;
      font-size: 4rem;
    }
  `,
  points: css`
    width: 80vw;
    @media (min-width: 960px) {
      width: 600px;
    }
  `,
  btn_container: css`
    width: 600px;
    height: auto;
  `,
  authority: css`
    position: absolute;
    bottom: 0;
    right: 5%;
    width: 40%;
    height: auto;
    @media (min-width: 960px) {
      width: 500px;
    }
  `,
}

export default HeroAdvertising
