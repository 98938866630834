import Image from 'next/image'
import { css, Theme } from '@emotion/react'
import { Box, Typography, Button } from '@mui/material'

import { CVButton } from '~/components/atoms/CVButton'

import Points from 'public/point_lp.svg'
import authority from 'public/authority.png'

const HeroCreative: React.FC = () => {
  return (
    <div css={styles.container}>
      <div css={styles.image}>
        <div css={styles.inner}>
          <Image src='/creative_hero.svg' layout='fill' objectFit='contain' />
        </div>
      </div>
      <Box css={styles.content}>
        <Typography
          variant='h1'
          component='h1'
          css={styles.copy}
          color='primary'>
          塾・教室のサイト制作には
          <br />
          <span>「コツ」</span>があります。
        </Typography>
        <div css={styles.points}>
          <Points />
        </div>
        <div css={styles.btn_container}>
          <CVButton
            url='/form'
            label='お見積・無料相談はこちら'
            micro='＼教室集客へ直結するサイト制作なら／'
          />
        </div>
      </Box>
      <div css={styles.authority}>
        <Image
          src={authority}
          width={1362}
          height={248}
          layout='intrinsic'
          alt='ウェブ解析士・IMA検定など有資格者がサポートいたします！'
        />
      </div>
    </div>
  )
}

const styles = {
  container: (theme: Theme) => css`
    position: relative;
    background-color: ${theme.palette.background.default};
    overflow: hidden;
    height: 100%;
    width: 100%;
  `,
  image: css`
    position: absolute;
    top: 35%;
    left: 0;
    width: 100vw;
    height: 100%;
    @media (min-width: 1200px) {
      top: 15%;
      width: 1100px;
    }
  `,
  inner: css`
    position: relative;
    width: 100%;
    height: 100%;
  `,
  content: css`
    position: absolute;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    width: 90vw;
    max-width: 1080px;
    top: 17%;
    right: 5%;
    @media (min-width: 960px) {
      justify-content: end;
      align-items: flex-end;
      width: 80vw;
    }
    @media (min-width: 1200px) {
      width: 70%;
      top: 25%;
      right: calc(15% - (500px - 25vw));
    }
  `,
  copy: css`
    font-style: normal;
    font-weight: 900;
    text-align: center;
    line-height: 3rem;
    font-size: 6vw;
    & span {
      color: #2ea595;
    }
    @media (min-width: 960px) {
      text-align: end;
      line-height: 6rem;
      font-size: 4rem;
    }
  `,
  points: css`
    width: 80vw;
    @media (min-width: 960px) {
      width: 600px;
      align-self: flex-end;
    }
  `,
  btn_container: css`
    width: 600px;
    height: auto;
    @media (min-width: 960px) {
      align-self: flex-end;
    }
  `,
  authority: css`
    position: absolute;
    bottom: 0;
    right: 5%;
    width: 40%;
    height: auto;
    @media (min-width: 960px) {
      width: 500px;
    }
  `,
}

export default HeroCreative
